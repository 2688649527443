import React from "react";

export const adminData =
    {
        email: 'DemoAdmin@gmail.com',
        password: "Password123!"
    }
export const projectManagerData =
    {
        email: 'DemoProjectManager1@gmail.com',
        password: "Password123!"
    }

export const developerData =
    {
        email: 'DemoDeveloper1@gmail.com',
        password: "Password123!"
    }
export const submitterData =
    {
        email: 'DemoSubmitter1@gmail.com',
        password: "Password123!"
    }